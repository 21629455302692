import { defaults, cloneDeep, chunk } from 'lodash';
import { HttpError } from 'ra-core';

import { dedupe } from '@hisports/parsers'

import { getWhere, getOrder, getLimit, getSkip, skipFilter } from './restClient/filters';
import { hasImage, uploadImage } from './restClient/images';
import { getIdProperty, hasContent, parseResponse, getTotal, isTemporaryId, getValidUntil } from './restClient/response';
import { handleInternalError, handleValidationError } from './restClient/errors';

import apiClient from './apiClient';

class RestClient {
  _getResource(value) {
    const resource = value.toLowerCase();
    switch (resource) {
      case 'leagues': return 'offices';
      case 'tournaments': return 'offices';
      case 'cups': return 'offices';
      case 'qualifications': return 'officials';
      case 'help': return 'articles';
      default: return resource;
    }
  }

  _getParams(params) {
    return cloneDeep(defaults({}, params, {
      pagination: { page: 1, perPage: 25 },
      sort: { field: 'id', order: 'ASC' },
      filter: {},
      id: null,
      ids: [],
      target: null,
      data: {},
      previousData: {},
    }));
  }

  async getList(_resource, _params) {
    const resource = this._getResource(_resource);
    const params = this._getParams(_params)

    // don't query unfiltered participant/account queries
    if (skipFilter(resource, params.filter)) return { data: [], total: -1 }

    const { _scope = 'Authorized', _include, _options, scheduleId, groupId, ...rest } = params.filter;

    if (resource === 'teams' && scheduleId && (Array.isArray(scheduleId) || groupId !== undefined)) {
      // this is a many-to-many relationship queried through a team input
      // querying via /schedules/:id/teams (below) is limited as it's unable to filter by group
      let scheduleteamsFilter = { scheduleId, groupId }
      if (Array.isArray(scheduleId) && Array.isArray(groupId)) {
        // assumes both scheduleId and groupId array are in order ex. scheduleId[0] -> groupId[0], etc...
        scheduleteamsFilter = { or: scheduleId.map((scheduleId, i) => ({ scheduleId, groupId: groupId[i] })) }
      }
      const scheduleteamsQuery = {
        scope: _scope,
        include: _include,
        options: _options,
        where: getWhere('scheduleteams', { ...params, filter: scheduleteamsFilter })
      }
      const scheduleteamsResponse = await apiClient('/scheduleteams', { params: { filter: scheduleteamsQuery } })

      const scheduleteams = scheduleteamsResponse?.data || []
      if (!scheduleteams.length) return { data: [], total: 0 };

      const teamIds = Array.from(new Set(scheduleteams.map(scheduleteam => scheduleteam.teamId)))
      const idFilter = { id: { inq: teamIds } }
      let teamsFilter = getWhere('teams', { ...query, filter: rest })
      if (teamsFilter) {
        teamsFilter = { and: [ teamsFilter, idFilter ] }
      } else {
        teamsFilter = idFilter;
      }

      const teamsResponse = await apiClient(`/teams`, { params: { filter: {
        ...query,
        where: teamsFilter
      } } })

      const data = parseResponse(resource, teamsResponse.data, teamsResponse.headers)
      return {
        data,
        total: getTotal(teamsResponse.headers, data),
        validUntil: getValidUntil(resource)
      }
    }

    const query = {
      scope: _scope,
      include: _include,
      options: _options,
      where: getWhere(resource, params),
      order: getOrder(params.sort),
      limit: getLimit(params.pagination),
      skip: getSkip(params.pagination),
    };

    if (resource === 'offices') {
      query.include = [...(query.include || []), 'logo'];
    }

    let url = `/${resource}`
    if (resource === 'teams' && scheduleId) {
      url = `/schedules/${scheduleId}/teams`;
    }

    const response = await apiClient(url, { params: { filter: query } });

    const data = parseResponse(resource, response.data, response.headers);
    return {
      data,
      total: getTotal(response.headers, data),
      validUntil: getValidUntil(resource)
    }
  }

  async getMany(_resource, _params) {
    const resource = this._getResource(_resource);
    const params = this._getParams(_params)

    const chunkLength = isNaN(params.ids?.[0]) ? 25 : 250;
    const chunks = chunk(dedupe(params.ids.flatMap(id => id)), chunkLength)
    return Promise.all(chunks.map(async ids => {
      const { _include, _options } = params.filter;
      const ID = getIdProperty(resource);
      const query = {
        include: _include,
        options: _options,
        where: {
          [ID]: { inq: ids }
        },
        scope: 'Tenant',
      }

      if (resource === 'offices') {
        query.include = [...(query.include || []), 'logo'];
      }

      const response = await apiClient(`/${resource}`, { params: { filter: query } })

      return parseResponse(resource, response.data, response.headers);
    })).then(chunks => ({
      data: chunks.flatMap(chunk => chunk),
      validUntil: getValidUntil(resource)
    }))
  }

  async getManyReference(_resource, _params) {
    let resource = this._getResource(_resource);
    const params = this._getParams(_params)

    let { id, target } = params;
    const { _scope = 'Authorized', _include, _options } = params.filter;

    const query = {
      scope: _scope,
      include: _include,
      options: _options,
      where: getWhere(resource, params),
      order: getOrder(params.sort),
      limit: getLimit(params.pagination),
      skip: getSkip(params.pagination),
    }

    if (resource === 'games' && target === 'conflicts') {
      // swapped to result in /games/:id/conflicts url
      // but resource needs to be games for react-admin's store
      resource = 'conflicts';
      target = 'games';
    }
    if (resource === 'officials' && target === 'participants') {
      resource = 'officialQualifications';
    }
    if (resource === 'listmembers' && target === 'lists') {
      resource = 'members';
    }
    if (resource === 'ruleoptionmembers' && target === 'ruleOptions') {
      resource = 'members'
    }
    if (resource === 'infractionaccumulationmembers' && target === 'infractionAccumulations') {
      resource = 'members'
    }
    if (resource === 'gamemessages') {
      resource = 'messages';
    }
    if (resource === 'gamedocuments') {
      resource = 'documents';
    }
    if (resource === 'officialclaimdocuments') {
      resource = 'claimdocuments';
    }
    if (resource === 'arenaslots' && target === 'surfaces') {
      resource = 'slots';
    }
    if (target === 'leagues') {
      target = 'offices'
    }

    if (resource === 'offices') {
      query.include = [...(query.include || []), 'logo'];
    }

    const isManyToMany = !target.endsWith('Id') && !(resource === 'games' && target === 'teams') && !(resource === 'assignrules' && target === 'value')
    const url = isManyToMany ? `/${target}/${id}/${resource}` : `/${resource}`;
    const response = await apiClient(url, { params: { filter: query } })

    const data = parseResponse(resource, response.data, response.headers);
    return {
      data,
      total: getTotal(response.headers, data),
      validUntil: getValidUntil(resource)
    }
  }

  async getOne(_resource, _params) {
    const resource = this._getResource(_resource);
    const params = this._getParams(_params)

    if (!params.id) throw new HttpError("Missing id", 400)
    let url = `/${resource}/${params.id}`
    let filter;
    switch (resource) {
      case 'teams':
      case 'offices': {
        filter = { include: 'logo' }
        break;
      }
      case 'officesettings': {
        url = `/offices/${params.id}/settings`
        break;
      }
      case 'branchofficesettings': {
        url = `/offices/${params.id}/branchSettings`
        break;
      }
      case 'schedulesettings': {
        url = `/schedules/${params.id}/settings`
        break;
      }
      case 'officeassignsettings': {
        url = `/offices/${params.id}/assignSettings`
        break;
      }
      case 'gameassignsettings': {
        url = `/games/${params.id}/assignSettings`
        break;
      }
    }

    try {
      const response = await apiClient(url, { params: { filter } })

      const data = parseResponse(resource, response.data, response.headers);
      return {
        data,
        validUntil: getValidUntil(resource)
      }
    } catch (err) {
      if (err.response.status === 404 && resource === 'schedulesettings') {
        const data = { id: params.id }
        return { data }
      }
      throw err;
    }
  }

  async create(_resource, _params) {
    const resource = this._getResource(_resource);
    const params = this._getParams(_params)

    // remove temporary ID before sending
    if (resource === 'notifications' && isTemporaryId(params?.data?.id)) {
      delete params.data.id;
    }

    if (hasContent(resource)) {
      params.data.content = JSON.stringify(params.data.content);
    }

    if (hasImage(resource, params.data)) {
      params.data = await uploadImage(resource, params.data)
    }

    if (params.data._scopes) {
      delete params.data._scopes;
    }

    let url = `/${resource}`;
    if (resource === 'gamemessages') {
      url = `/games/${params.data.gameId}/messages`
    }

    try {
      const response = await apiClient.post(url, params.data)
      const data = parseResponse(resource, response.data, response.headers);
      return { data }
    } catch (err) {
      if (err.response.status === 422) throw handleValidationError(_resource, err.response)
      if (err.response.status === 500) throw handleInternalError(_resource, err.response)
      throw err;
    }
  }

  async update(_resource, _params) {
    const resource = this._getResource(_resource);
    const params = this._getParams(_params)

    if (hasContent(resource)) {
      params.data.content = JSON.stringify(params.data.content);
    }

    if (hasImage(resource, params.data)) {
      params.data = await uploadImage(resource, params.data)
    }

    if (params.data._scopes) {
      delete params.data._scopes;
    }

    let method = 'PATCH';
    let url = `/${resource}/${params.id}`

    switch (resource) {
      case 'officesettings': {
        method = 'PUT';
        url = `/offices/${params.id}/updateSettings`;
        break;
      }
      case 'branchofficesettings': {
        method = 'PUT';
        url = `/offices/${params.id}/updateBranchSettings`;
        break;
      }
      case 'officeassignsettings': {
        method = 'PUT';
        url = `/offices/${params.id}/updateAssignSettings`;
        break;
      }
    }

    try {
      const response = await apiClient(url, {
        method,
        data: params.data,
      });

      const data = parseResponse(resource, response.data, response.headers);
      return { data }
    } catch (err) {
      if (err.response.status === 404) return this.create(resource, params)
      if (err.response.status === 422) throw handleValidationError(_resource, err.response)
      if (err.response.status === 500) throw handleInternalError(_resource, err.response)
      throw err;
    }
  }

  async updateMany(_resource, _params) {
    const resource = this._getResource(_resource);
    const params = this._getParams(_params)

    const ID = getIdProperty(resource);
    const where = {
      [ID]: { inq: params.ids }
    }

    if (params.data._scopes) {
      delete params.data._scopes;
    }

    await apiClient.post(`/${resource}/update`, params.data, {
      params: { where },
    })

    return { data: params.ids }
  }

  async delete(_resource, _params) {
    const resource = this._getResource(_resource);
    const params = this._getParams(_params)

    let url = `/${resource}/${params.id}`
    if (resource === 'gamemessages') {
      url = `/games/${params.data.gameId}/messages/${params.id}`
    }

    if (resource === 'gamedocuments') {
      url = `/games/${params.data.gameId}/documents/${params.id}`
    }

    if (resource === 'officialclaimdocuments') {
      url = `/games/${params.data.gameId}/claimdocuments/${params.id}`
    }

    try {
      await apiClient.delete(url, { data: params.data })
    } catch (err) {
      if (err.response.status === 422) throw handleValidationError(resource, err.response)
    }

    return { data: params.previousData }
  }

  async deleteMany(_resource, _params) {
    const resource = this._getResource(_resource);
    const params = this._getParams(_params);

    let where = {};

    if (params.ids?.length) {
      const ID = getIdProperty(resource);
      where[ID] = { inq: params.ids };
    } else if (params.where) {
      where = params.where;
    } else {
      throw new Error('Unsupported')
    }

    const url = `/${resource}`
    await apiClient.delete(url, {
      params: { where }
    })

    return { data: [] };
  }
}

export default () => {
  const client = new RestClient();

  return {
    getList: client.getList.bind(client),
    'GET_LIST': client.getList.bind(client),
    getMany: client.getMany.bind(client),
    'GET_MANY': client.getMany.bind(client),
    getManyReference: client.getManyReference.bind(client),
    'GET_MANY_REFERENCE': client.getManyReference.bind(client),
    getOne: client.getOne.bind(client),
    'GET_ONE': client.getOne.bind(client),
    create: client.create.bind(client),
    'CREATE': client.create.bind(client),
    createMany: client.create.bind(client),
    'CREATE_MANY': client.create.bind(client),
    update: client.update.bind(client),
    'UPDATE': client.update.bind(client),
    updateMany: client.updateMany.bind(client),
    'UPDATE_MANY': client.updateMany.bind(client),
    delete: client.delete.bind(client),
    'DELETE': client.delete.bind(client),
    deleteMany: client.deleteMany.bind(client),
    'DELETE_MANY': client.deleteMany.bind(client),
  }
}
